.decolines {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.decolines--fixed {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.decoline {
  position: absolute;
}

.decolines-hide {
  display: none;
}
