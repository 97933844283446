.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* min-height: 70vh; */
  padding: 200px 100px;
  padding-bottom: 80px;
  color: #fff;
  gap: 20px;
  transition: all 0.5s;
}

.footer-section-1 {
  display: flex;
  gap: 120px;
}

.footer-section-email {
  display: flex;
  flex-direction: column;
}

.footer-section-social {
  display: flex;
  flex-direction: column;
}

.footer-heading {
  font-size: 1.8vw;
  margin-bottom: 0.5em;
}

.footer-subheading {
  font-size: 2.7vw;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

.footer-subheading:hover:after {
  width: 100%;
}

.footer-subheading::after {
  content: "";
  background: #ffd23f;
  width: 0;
  height: 50%;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: -1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer-subheading-animate {
  -webkit-animation: menu-links-effect;
  animation: menu-links-effect;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.footer-section-3 {
  display: flex;
}

.footer-brand {
  display: flex;
  gap: 30px;
}

.footer-brand-text {
  font-size: 1.2vw;
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .footer {
    padding: 150px 30px;
    padding-bottom: 30px;
    gap: 20px;
  }
  .footer-section-1 {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
  }
  .footer-heading {
    font-size: 3vw;
    margin-bottom: 0.5em;
  }
  .footer-subheading {
    font-size: 4vw;
    margin-bottom: 0.5em;
    width: fit-content;
  }
  .footer-brand-text {
    font-size: 3vw;
  }
}
