:root {
  --dmsans-regular: "DMSans-Regular";
  --dmsans-bold: "DMSans-Bold";
  --dmsans-semibold: "DMSans-SemiBold";
  --extra-bold: "Monument-Ultrabold";
  --regular: "Monument-Regular";

  --primary-theme-color: #8c52ff;
}

.app-text-extra-bold {
  font-family: var(--extra-bold);
}

.app-text-regular {
  font-family: var(--regular);
}

.app-text-dm-regular {
  font-family: var(--dmsans-regular);
}

.app-text-dm-semibold {
  font-family: var(--dmsans-semibold);
}

.app-text-dm-bold {
  font-family: var(--dmsans-bold);
}

.App {
  background-color: var(--primary-theme-color);
  min-height: 100vh;
  overflow-x: hidden;

  transition: background 0.7s;
}

.App-white {
  background-color: #e5e5e5;
}

html {
  scroll-behavior: smooth;
}

.appears {
  -webkit-animation: appears;
  animation: appears;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.appearing_text {
  -webkit-animation: appearing_text;
  animation: appearing_text;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@keyframes appearing_text {
  0% {
    -webkit-clip-path: inset(95% 0 0 0);
    clip-path: inset(95% 0 0 0);
    opacity: 0;
    -webkit-transform: translate3d(0, 60px, 0);
    transform: translate3d(0, 60px, 0);
  }

  to {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes appearing_text_reverse {
  0% {
    -webkit-clip-path: inset(-95% 0 0 0);
    clip-path: inset(-95% 0 0 0);
    opacity: 0;
  }

  to {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}

@keyframes appears {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes menu-links-effect {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes loaderOutro {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    z-index: -89;
  }
}

@keyframes loaderOutro {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    z-index: -89;
  }
}
