.company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  padding: 100px;
  padding-bottom: 150px;
  /* color: #fff; */
  color: #1b2845;
  gap: 45px;
  opacity: 0;
  transition: all 1s;
}

.company-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.company-img {
  /* width: 450px; */
  /* height: auto; */
  /* opacity: 0; */

  max-width: 100%;
  position: relative;
  margin: auto;
  display: block;
  height: auto;
  top: -100px;
}

.company-text-container {
  display: flex;
  flex-direction: column;

  gap: 35px;
  flex: 1;
}

.company-heading {
  font-size: 2vw;
  opacity: 0;
  color: #1b2845;
}

.company-subheading {
  font-size: 2.5vw;
  opacity: 0;
}

.company-text {
  font-size: 1.6vw;
  opacity: 0;
}

.company-section-1 {
  display: flex;
  gap: 25px;
}

.company-section-2 {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .company {
    padding: 30px;
  }
  .company-text-container {
    gap: 25px;
  }
  .company-section-1 {
    flex-direction: column;
    gap: 100px;
  }
  .company-heading {
    font-size: 4vw;
  }
  .company-subheading {
    font-size: 3.5vw;
  }
  .company-text {
    font-size: 3vw;
  }
}
