.header {
  display: flex;
  justify-content: space-between;
  padding: 18px 100px;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  color: #fff;
  z-index: 1;
}

.header-logo {
  width: 85px;
  height: auto;

  opacity: 0;
}

.header-white-bg {
  color: #1b2845;
}

.header-nav {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: space-evenly;
}

.header-nav-text {
  font-size: 1.4vw;
  letter-spacing: 1px;

  -webkit-animation: appearing_text;
  animation: appearing_text;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  /* -webkit-animation-delay: 1s;
  animation-delay: 1s; */
}

.menu-toggle {
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  height: 18px;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  cursor: pointer;

  opacity: 0;
}

.menu-toggle span {
  height: 2px;
  background: #fff;
  width: 30px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.menu-toggle span:nth-of-type(2) {
  width: 17px;
}

.menu-toggle:hover span {
  width: 30px !important;
}

.menu-toggle-white-bg span {
  background: #1b2845;
}

.appearing_text_reverse {
  -webkit-animation: appearing_text_reverse;
  animation: appearing_text_reverse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.header-right {
  display: flex;
  gap: 30px;
  align-items: center;
}

.header-email {
  font-size: 1.2vw;
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .header {
    padding: 18px 30px;
  }

  .header-logo {
    width: 55px;
  }

  .menu-toggle span {
    width: 25px;
  }

  .header-right {
    gap: 15px;
  }

  .header-email {
    font-size: 3vw;
  }
}
