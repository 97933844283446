.what-we-do {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  padding: 0 100px;
  color: var(--primary-theme-color);
  opacity: 0;
  transition: all 0.5s;
}

.what-we-do-visible {
  opacity: 1;
}

.what-we-do-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.what-we-do-img {
  width: 650px;
  height: auto;
}

.what-we-do-text-container {
  display: flex;
  flex-direction: column;

  gap: 40px;
  /* flex: 1.5; */
}

.what-we-do-heading {
  font-size: 5vw;
  color: #ffd23f;
  text-transform: uppercase;
}

.what-we-do-text {
  font-size: 1.6vw;
  color: #1b2845;
}

.what-we-do-text-section-1 {
  width: 85%;
}

.what-we-do-text-section-2 {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.what-we-do-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.what-we-do-subheading {
  font-size: 3.1vw;
  color: #1b2845;
}

.what-we-do-subheading span {
  color: #ffd23f;
}

.what-we-do-section-2-row-1,
.what-we-do-section-2-row-2,
.what-we-do-section-2-row-3,
.what-we-do-section-2-row-4 {
  display: flex;
  justify-content: space-between;
}

.what-we-do-section-2-row-1 p {
  flex: 0 0 49%;
}
.what-we-do-section-2-row-2 p {
  flex: 0 0 49%;
}
.what-we-do-section-2-row-3 p {
  flex: 0 0 49%;
}
.what-we-do-section-2-row-4 p {
  flex: 0 0 49%;
}

.what-we-do-section-2-row-1 span {
  flex: 0 0 49%;
}
.what-we-do-section-2-row-2 span {
  flex: 0 0 49%;
}
.what-we-do-section-2-row-3 span {
  flex: 0 0 49%;
}
.what-we-do-section-2-row-4 span {
  flex: 0 0 49%;
}

.download-btn-container {
  margin-top: 2em;
}

.download-btn {
  border: none;
  background-color: #ffd23f;
  padding: 14px 18px;
  font-size: 1vw;
  color: #1b2845;
  cursor: pointer;
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .what-we-do {
    padding: 0 30px;
  }
  .what-we-do-text-container {
    gap: 20px;
  }
  .what-we-do-heading {
    font-size: 8vw;
  }
  .what-we-do-text {
    font-size: 3vw;
  }
  .what-we-do-text-section-1 {
    width: 100%;
  }
  .what-we-do-text-section-2 {
    gap: 10px;
  }
  .download-btn {
    font-size: 2.3vw;
    padding: 10px 12px;
  }
}
