.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  padding: 0 100px;
  color: #fff;

  min-width: 100%;
  box-sizing: border-box;
}

.intro-section-1 {
  display: flex;
  flex-direction: column;
  /* min-width: 100%; */
  min-height: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  opacity: 1;
}

.intro-logo-text {
  font-size: 3.5vw;
  letter-spacing: 1px;
  text-align: center;
  opacity: 0;

  -webkit-animation: appearing_text;
  animation: appearing_text;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.intro-logo {
  width: 250px;
  height: auto;
  opacity: 0;

  -webkit-animation: appearing_text;
  animation: appearing_text;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.intro-text {
  font-size: 1.5vw;
  letter-spacing: 1px;
  text-align: center;
  opacity: 0;

  -webkit-animation: appearing_text;
  animation: appearing_text;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.intro-text-small {
  font-size: 0.8vw;
  letter-spacing: 1px;
  text-align: center;
  opacity: 0;
  margin-bottom: 10px;

  -webkit-animation: appearing_text;
  animation: appearing_text;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.intro-text-medium {
  font-size: 3vw;
  letter-spacing: 1px;
  text-align: center;
  opacity: 0;

  -webkit-animation: appearing_text;
  animation: appearing_text;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .intro {
    padding: 0 30px;
  }
  .intro-logo {
    width: 120px;
  }
  .intro-text {
    font-size: 3vw;
  }
  .intro-logo-text {
    font-size: 4vw;
  }
  .intro-text-small {
    font-size: 2vw;
  }
  .intro-text-medium {
    font-size: 5.5vw;
  }
}
