.loader {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 99999;
  text-align: center;
}

.loader-move {
  -webkit-animation: loaderOutro;
  animation: loaderOutro;
  -webkit-animation-timing-function: ease, step-start,
    cubic-bezier(0.1, 0.7, 1, 0.1);
  animation-timing-function: ease, step-start, cubic-bezier(0.1, 0.7, 1, 0.1);
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.loader p {
  color: var(--primary-theme-color);
  letter-spacing: 1.5px;
  font-size: 1.4vw;
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .loader p {
    font-size: 3vw;
  }
}