.pitch-deck-root {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.pitch-deck-show {
  display: flex;
}

.pitch-deck {
  background-color: #e5e5e5;
  border-radius: 15px;
  padding: 35px 50px;
  position: relative;
}

.pitch-heading {
  font-size: 1.6vw;
  margin-bottom: 10px;
}

.pitch-subheading {
  font-size: 0.9vw;
}

.pitch-close-box {
  position: absolute;
  right: 30px;
  display: flex;
  cursor: pointer;
  height: auto;
}

.pitch-close {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pitch-close-arc-1,
.pitch-close-arc-2 {
  height: 2px;
  width: 25px;
  background-color: #000;
}

.pitch-close-arc-1 {
  rotate: 45deg;
}
.pitch-close-arc-2 {
  position: absolute;
  rotate: 135deg;
}

.pitch-deck-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pitch-divider {
  width: 100%;
  border-top: 1px solid #ababab;
}

.pitch-list {
  display: flex;
  margin-bottom: 10px;
  gap: 50px;
  color: #1b2845;
}
.pitch-list ul {
  margin-top: 12px;
}

.pitch-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  padding-top: 30px;
}

.pitch-input {
  padding: 10px 12px;
  /* border-radius: 10px; */
  border: none;
  outline: none;
  font-size: 16px;

  width: 90%;
}

.pitch-btn {
  background-color: #ffd23f;
  padding: 8px 15px;
  font-size: 0.9vw;
  color: #1b2845;
  cursor: pointer;
  margin-top: 20px;
  width: 90px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pitch-btn-disabled {
  background-color: #bfbfbf;
  box-shadow: 0 1px 16px 2px #a0a0a033;
}

.spinner {
  width: 16px;
  padding: 3px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ffd23f;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.pitch-submit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.pitch-check {
  width: 40px;
  margin-bottom: 1em;
}

.pitch-check-text {
  font-size: 1vw;
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .pitch-deck {
    padding: 15px 20px;
  }
  .pitch-heading {
    font-size: 3.6vw;
    margin-bottom: 0px;
  }

  .pitch-subheading {
    font-size: 2.4vw;
  }
  ul {
    padding-inline-start: 20px;
  }
  ul > li {
    font-size: 2vw;
  }
  .pitch-form {
    gap: 10px;

    padding-top: 20px;
  }
  .pitch-input {
    padding: 5px 6px;
    font-size: 10px;
  }
  .pitch-list {
    gap: 20px;
  }
  .pitch-btn {
    padding: 4px 4px;
    font-size: 2vw;
    margin-top: 6px;
    width: auto;
  }
  .pitch-close-box {
    top: 10px;
    right: 20px;
  }
  .pitch-close {
    height: 15px;
    width: 15px;
  }

  .pitch-close-arc-1,
  .pitch-close-arc-2 {
    height: 2px;
    width: 15px;
  }
}
