.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-theme-color);

  padding: 35px 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  position: fixed;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  opacity: 0;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}

.menu-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  z-index: 2000;
}

.close-box {
  display: flex;
  position: absolute;
  color: #fff;
  /* top: 55px; */
  right: 100px;
  font-size: 50px;
  cursor: pointer;
  height: auto;
}

.close {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-arc-1,
.close-arc-2 {
  height: 2px;
  width: 40px;
  background-color: #fff;
}

.close-arc-1 {
  rotate: 45deg;
}
.close-arc-2 {
  position: absolute;
  rotate: 135deg;
}

.menu-text-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 80%;
  height: 30%;
}

.menu-nav-text {
  color: #fff;
  font-size: 3.2vw;
  letter-spacing: 1px;
  opacity: 0;
  cursor: pointer;
}

.menu-nav-text:hover:after {
  width: 100%;
}

.menu-nav-text::after {
  content: "";
  background: #ffd23f;
  width: 0;
  height: 50%;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: -1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.menu-nav-text-animate {
  -webkit-animation: menu-links-effect;
  animation: menu-links-effect;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.option-1 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.option-2 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.option-3 {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
.option-4 {
  -webkit-animation-delay: 1.2s !important;
  animation-delay: 1.2s !important;
}

.menu-footer-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.menu-footer-container span {
  font-size: 1.1vw;
  color: #fafafa;
}

.menu-footer-right {
  display: flex;
  gap: 25px;
}

.menu-link {
  cursor: pointer;
}

.menu-download-btn-container {
  /* position: absolute;
  bottom: 250px;
  left: 770px;
  opacity: 0;
  */
  opacity: 0;
}

.menu-download-btn {
  border: none;
  background-color: #ffd23f;
  padding: 14px 18px;
  font-size: 1vw;
  color: #1b2845;
  cursor: pointer;
}

.menu-download-btn-animate {
  -webkit-animation: appears;
  animation: appears;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.option-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .close-box {
    top: 25px;
    right: 30px;
  }
  .close {
    height: 30px;
    width: 30px;
  }

  .close-arc-1,
  .close-arc-2 {
    height: 2px;
    width: 30px;
  }
  .menu-text-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 80px;
  }
  .menu-nav-text {
    font-size: 5vw;
    letter-spacing: 1px;
  }
  .menu-footer-container span {
    font-size: 2.6vw;
  }
  .menu-footer-left {
    display: flex;
  }
  .menu-footer-right {
    gap: 8px;
  }
  .menu-download-btn-container,
  .dummy-div {
    display: none;
  }
}
