.foundation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  min-height: 100vh;
  padding: 0 100px;
  color: #ffffff;
  gap: 25px;
  transition: all;

  min-width: 100%;
  box-sizing: border-box;
}

.foundation-img {
  position: absolute;
  width: 600px;
  height: auto;

  opacity: 0;
}

.foundation-text-container {
  display: flex;
  flex-direction: column;
  z-index: 1;
  gap: 70px;

  position: absolute;
  width: 55%;
}

.foundation-text {
  font-size: 2vw;
  letter-spacing: 1px;
  text-align: center;
  opacity: 0;
}

.foundation-text-1 {
  text-align: left;
}

.foundation-text-2 {
  text-align: right;
}
